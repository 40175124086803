import { withState, Link, Helmet } from '@mna/react'
import api from '@mna/api'
import schema from '@mna/schema'
import Form from '@mna/react-ui/Form'
import { encrypt, decrypt } from '@mna/crypt'
import generatePassword from '@mna/crypt/password'
import selectElement from '@mna/dom/selectElement'
import WideContent from 'templates/WideContent'

const noteSchema = schema(type => ({
  text: type.string
}))

const CreateNote = ({ store, appRoutePrefix }) =>
  <div>
    <h1>Create Note</h1>
    <Form
      onValidate={noteSchema}
      onSubmit={data => {
        store.actions.generateOneTimeLink({ ...data, appRoutePrefix })
      }}
    >{({ fields, invalidFields }) =>
        <div>
          <textarea
            className={`${invalidFields.text ? 'is-error' : ''}`}
            name="text" rows="6" autoFocus
            value={fields.text}
          ></textarea>

          <button type="submit" className="btn btn-primary mt-2 w-100">
            Generate link
          </button>
        </div>
      }
    </Form>
  </div>

const NoteLink = ({ store, link, route }) =>
  <div>
    <h1>Note Link</h1>
    <p>Please copy the link to clipboard before closing this window.</p>
    <pre className="bg-silver">
      <code className="bg-silver color-black text-body-font text-regular text-size-normal" ref={el => el && selectElement(el)}>{link}</code>
    </pre>
    <p>The note will be deleted after being read once.</p>
    <hr/>
    <div className="row">
      <div className="float-left">
      <Link to={`mailto:?body=${link}`}>
      <button type="button" className="btn btn-primary">Send as e-mail</button>
    </Link>
      </div>
      <div className="float-right">
      <button type="button" className="btn bg-green color-white"
      onClick={() => store.actions.removeOneTimeLink()}>
        Create new note
    </button>

      </div>
    </div>

    {/* <Link to={route} className="ml-1">
      <button type="button" className="btn bg-yellow color-black">Destroy it now</button>
    </Link> */}
  </div>

const Page = ({ store, appRoutePrefix }) =>
  <WideContent articleWidth={true}>

    <Helmet title="HushNote" />

    { !store.state.oneTimeLink
      ? <CreateNote {...{
        store,
        appRoutePrefix
      }} />
      :
      <NoteLink {...{
        store,
        link: store.state.oneTimeLink,
        route: store.state.oneTimeRoute
      }} />
    }
  </WideContent>

const createState = () => ({
  oneTimeLink: '',
  oneTimeRoute: ''
})

export default withState({
  createState,
  actions: {
    async generateOneTimeLink({ store, text, appRoutePrefix }) {

      const key = generatePassword()
      const result = await encrypt(text, key)

      api.post('/api', {
        type: 'hushnote',
        action: 'saveNote',
        data: { text: result }
      }).then(({ id }) => {

        const oneTimeRoute = appRoutePrefix+`/note/${id}#${key}`
        const oneTimeLink = `${window.location.protocol}//${window.location.host}${oneTimeRoute}`

        store.setState({
          oneTimeLink,
          oneTimeRoute
        })

      }).catch(console.error)

    },
    removeOneTimeLink({ store }) {
      store.setState(createState())
    }
  }
})(Page)
